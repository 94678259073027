/**
 *  Shopro全局配置文件
 */

let BASE_URL, IMG_URL
if (process.env.NODE_ENV === 'production') {
  // BASE_URL = 'http://mhsj.test.svkeji.cn' //后台根域名
/*  IMG_URL = 'http://mhsg_test.svkeji.cn' //全局网络图片地址变量，css背景图片地址变量在uni.scss
  BASE_URL = 'http://mhsg_test.svkeji.cn' //后台根域名*/
  IMG_URL = 'https://mhsg-app.svkeji.cn' //全局网络图片地址变量，css背景图片地址变量在uni.scss
  BASE_URL = 'https://mhsg-app.svkeji.cn'  //后台根域名
  // IMG_URL = 'https://wxapp.cqyichuang.cn' //全局网络图片地址变量，css背景图片地址变量在uni.scss
} else {
  BASE_URL = 'http://mhsg_test.svkeji.cn' //后台根域名
  IMG_URL = 'http://mhsg_test.svkeji.cn' //全局网络图片地址变量，css背景图片地址变量在uni.scss
  // BASE_URL = 'http://mhsg_test.svkeji.cn' //后台根域名
  // IMG_URL = 'https://wxapp.cqyichuang.cn' //全局网络图片地址变量，css背景图片地址变量在uni.scss

}
export { BASE_URL, IMG_URL }
export const API_URL = `${BASE_URL}/api/` //后台接口域名
// export const MAP_KEY = '*'; //高德地图开发者Web服务key,逆坐标解析
export const HAS_LIVE = false //后台是否开通直播权限,根据情况在manifest.json中，开启注释相应组件的引入，pages.json中打开直播
